import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import { Delete, Edit, Home, Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    MenuItem,
    Popover,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//services
import ApiCalls from '../../services/try';

//internals
import DeviceImg from "../../assets/images/devices.png";

//externals
import swal from 'sweetalert';
import secureSessionStorage from "../../crypto";

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

// ----------------------------------------------------------------------

const ManageDevice = () => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openDeviceHistory, setOpenDeviceHistory] = useState(false);
    const [useDeviceName, setUseDeviceName] = useState(false); // Flag to determine which name field to use
    const [showPassphrase, setShowPassphrase] = useState(false);

    const [visiblePassphrases, setVisiblePassphrases] = useState({});

    const [device, setDevice] = useState([]);
    const [deviceProfiles, setDeviceProfiles] = useState([]);
    const [selectedDevicetoView, setSelectedDevicetoView] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedProfile, setSelectedProfile] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    // Masking Function for Passphrase
    const maskPassphrase = (passphrase, visibleStartChars = 3, visibleEndChars = 3) => {
        if (!passphrase) return 'N/A';
        const passLength = passphrase.length;
        const maskLength = passLength - (visibleStartChars + visibleEndChars);

        // If the passphrase is too short to mask, return as-is
        if (maskLength <= 0) return passphrase;

        return (
            passphrase.slice(0, visibleStartChars) + // Visible start
            '*'.repeat(maskLength) + // Masked middle
            passphrase.slice(-visibleEndChars) // Visible end
        );
    };

    // Toggle function for each device's passphrase
    const togglePassphrase = (deviceId) => {
        setVisiblePassphrases((prev) => ({
            ...prev,
            [deviceId]: !prev[deviceId], // Toggle visibility for this device ID
        }));
    };

    const loadDeviceList = () => {
        setLoading(true);
        var tenantId = secureSessionStorage.getItem("tenantId");
        var companyId = secureSessionStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureSessionStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("compId");
            physicianId = secureSessionStorage.getItem("UUID");
        }
        console.log(companyId, '********************');
        ApiCalls.getdetails("PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice")
            .then((response) => {
                console.log(response.data.device);
                if (response.data.device != undefined) {
                    setDevice(response.data.device);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setDevice([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setDevice([]);
            })
    }

    // Fetch all device profiles and add "All" option at the beginning
    const loadDeviceProfiles = () => {
        var tenantId = secureSessionStorage.getItem("tenantId");
        var companyId = secureSessionStorage.getItem("compId");
        var physicianId = "";
        var rolename = secureSessionStorage.getItem("rolename");
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
            companyId = "0";
            physicianId = "0";
        } else if (rolename === "Company") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("UUID");
            physicianId = "0";
        } else if (rolename === "Physician") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("compId");
            physicianId = secureSessionStorage.getItem("UUID");
        }

        ApiCalls.getdetails(`tenants/${tenantId}/companies/0/physicians/0/devicemodels`)
            .then((response) => {
                if (response.data.deviceMode !== undefined) {
                    const profiles = response.data.deviceMode;
                    const allOption = { deviceUUid: '', modelName: 'All' }; // Default "All" option
                    setDeviceProfiles([allOption, ...profiles]); // Add "All" at the start
                } else {
                    setDeviceProfiles([]);
                }
            })
            .catch((error) => {
                console.error("Error loading device profiles:", error);
                setDeviceProfiles([]);
            });
    };

    useEffect(() => {
        // loadDeviceList();
        loadDeviceProfiles();
        fetchDevices();
    }, [])

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const fetchDevices = (profileId = '') => {
        setLoading(true);
        const tenantId = secureSessionStorage.getItem("tenantId");
        const companyId = secureSessionStorage.getItem("compId");
        let apiUrl = '';

        // Determine API endpoint based on profile selection
        if (profileId) {
            apiUrl = `model/${profileId}/getDeviceDetailsByProfile`;
            setUseDeviceName(true); // Indicate that `deviceName` is used in this API response
        } else {
            apiUrl = `PhysicianId/${tenantId}/company/${companyId}/showdevice`;
            setUseDeviceName(false); // Indicate that `devicename` is used in this API response
        }

        ApiCalls.getdetails(apiUrl)
            .then((response) => {
                const devicesList = (response.data.device || []).map((device) => ({
                    deviceuuid: device.deviceuuid,
                    deviceName: device.deviceName || device.devicename, // Normalize to `deviceName`
                    decmacid: device.decmacid,
                    deviceimageName: device.deviceimageName,
                    modelSchema: device.modelSchema || [], // Handle undefined modelSchema
                    passphrase: device.passphrase || ""
                }));
                setDevice(devicesList); // Set the normalized device list
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching devices:", error);
                setLoading(false);
                setDevice([]);
            });
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    // Find the name of the selected profile
    const selectedProfileName = deviceProfiles.find((profile) => profile.deviceUUid === selectedProfile)?.modelName || 'All';

    const handleProfileChange = (profileId) => {
        setSelectedProfile(profileId); // Update selected profile
        fetchDevices(profileId);       // Fetch devices based on selected profile
        setAnchorEl(null);             // Close popover on selection
    };

    // Filter devices based on search term
    const filteredDevices = device.filter((devices) =>
    (devices.deviceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        devices.decmacid?.toLowerCase().includes(searchTerm.toLowerCase()))
    );


    // const filteredDevices = device.filter((device) =>
    //     device.devicename.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     device.decmacid.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const handleDeleteDevice = (deviceId) => {
        ApiCalls.getdetails("device/" + deviceId + "/checkForConnectedBeds")
            .then((response) => {
                console.log(response.data);
                if (response.data == true) {
                    swal({
                        title: "Cannot Delete",
                        text: `This ${secureSessionStorage.getItem("DeviceName")} cannot be deleted at the moment as they are currently connected.`,
                        icon: "warning",
                        buttons: {
                            ok: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "btn-danger",
                                closeModal: true,
                            },
                        },
                        dangerMode: true,
                    });
                } else {
                    swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                var rolename = secureSessionStorage.getItem("rolename");
                                if (rolename === "Tenant") {
                                    ApiCalls.delete("deletedevices/" + deviceId)
                                        .then((response) => {
                                            console.log(response.data);
                                            if (response.data.status.message === "Success") {
                                                swal("Deleted Successfully!", {
                                                    icon: "success",
                                                });
                                                loadDeviceList();
                                            }
                                        });
                                } else if (rolename === "Company") {
                                    ApiCalls.delete("deletedevicesByCompany/" + deviceId)
                                        .then((response) => {
                                            console.log(response.data);
                                            if (response.data.status.message === "Success") {
                                                swal("Deleted Successfully!", {
                                                    icon: "success",
                                                });
                                                loadDeviceList();
                                            }
                                        });
                                }

                            }
                        });
                }
            })


    }

    const handleEditDevice = (device) => {
        secureSessionStorage.setItem("deviceIdToEdit", device.decmacid);
        secureSessionStorage.setItem("deviceIdSelected", device.deviceuuid);
        setRedirectToEdit(true);
    }

    const handleClickDevice = (deviceDetails) => {
        console.log(deviceDetails.decmacid);
        secureSessionStorage.setItem("deviceIdToEdit", deviceDetails.decmacid)
        secureSessionStorage.setItem("deviceIdSelected", deviceDetails.deviceuuid);
        setSelectedDevicetoView(deviceDetails);
        setOpenDeviceHistory(true);
    }

    if (openDeviceHistory) {
        return (<Redirect to={'/devicehistory'} />);
    }

    if (redirectToEdit) {
        return (<Redirect to={'/editdevice'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/registerdevice'} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>


                        <StyledBreadcrumb label={"Manage " + secureSessionStorage.getItem("DeviceName")} />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureSessionStorage.getItem("rolename") === "Physician" ? "none" : ""
                    }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setRedirectToForm(true)}>
                        New {secureSessionStorage.getItem("DeviceName")}
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h4" gutterBottom>
                                {secureSessionStorage.getItem("DeviceName") + " List"}
                            </Typography>
                        }
                        action={
                            <Stack direction="row" spacing={2} alignItems="center">
                                <TextField
                                    label={`Search ${secureSessionStorage.getItem("DeviceName")}`}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <Tooltip title="Seelct Device Profile">
                                    <Button variant="outlined" size="large" endIcon={<Iconify icon="mdi:filter" />} onClick={handlePopoverOpen}>
                                        {selectedProfileName}
                                    </Button>
                                </Tooltip>

                                <Popover
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    PaperProps={{
                                        sx: {
                                            p: 1,
                                            mt: 1.5,
                                            ml: 0.75,
                                            width: 180,
                                            '& .MuiMenuItem-root': {
                                                px: 1,
                                                typography: 'body2',
                                                borderRadius: 0.75,
                                            },
                                        },
                                    }}
                                >
                                    <Stack spacing={0.75}>
                                        {deviceProfiles.map((profile) => (
                                            <MenuItem
                                                key={profile.deviceUUid}
                                                selected={profile.deviceUUid === selectedProfile} // Highlight selected
                                                onClick={() => handleProfileChange(profile.deviceUUid)} // Update selected profile
                                            >
                                                {profile.modelName}
                                            </MenuItem>
                                        ))}
                                    </Stack>
                                </Popover>
                            </Stack>
                        }
                    />

                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredDevices.length > 0 ? (
                                <Grid container spacing={2}>
                                    {filteredDevices.map((device) => (
                                        <Grid item xs={12} sm={6} md={4} key={device.deviceuuid}>
                                            <Card sx={{
                                                maxWidth: 300,
                                                boxShadow: 3,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                '&:hover': {
                                                    border: '1px solid #D1E9FC',
                                                    boxShadow: 'none'
                                                }
                                            }}>
                                                <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title={device.deviceName} />
                                                <Avatar
                                                    variant='rounded'
                                                    alt={device.deviceName}
                                                    src={(device.deviceimageName && device.deviceimageName !== "null") ? device.deviceimageName : DeviceImg}
                                                    sx={{ width: 150, height: 150, objectFit: 'contain', cursor: "pointer" }}
                                                    onClick={() => handleClickDevice(device)}
                                                />
                                                <CardContent
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}
                                                    onClick={() => handleClickDevice(device)}
                                                >
                                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                        Device Id : {device.decmacid}
                                                    </Typography>

                                                    {/* Passphrase */}
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{ color: 'text.primary', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        Passphrase:&nbsp;
                                                        {visiblePassphrases[device.decmacid] && device.passphrase
                                                            ? device.passphrase
                                                            : maskPassphrase(device.passphrase, 3)} {/* Mask/Unmask Logic */}
                                                        <IconButton
                                                            onMouseDown={(e) => e.preventDefault()} // Prevent focus issue
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent CardContent click
                                                                togglePassphrase(device.decmacid); // Toggle visibility
                                                            }}
                                                            size="small"
                                                        >
                                                            {visiblePassphrases[device.decmacid] ? (
                                                                <VisibilityOff color="primary" fontSize="10px" />
                                                            ) : (
                                                                <Tooltip title="Show passphrase" placement="right-end" >
                                                                    <Visibility color="primary" fontSize="10px" />
                                                                </Tooltip>
                                                            )}
                                                        </IconButton>
                                                    </Typography>

                                                    {/* Display Model Name if it exists */}
                                                    {device.modelSchema[0]?.modelName && (
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary', mt: 1 }}>
                                                            Model Name : {device.modelSchema[0].modelName}
                                                        </Typography>
                                                    )}

                                                    {/* Display Model Number if it exists */}
                                                    {device.modelSchema[0]?.modelNumber && (
                                                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                            Model Number: {device.modelSchema[0].modelNumber}
                                                        </Typography>
                                                    )}
                                                </CardContent>
                                                <CardActions>
                                                    <Button variant="outlined" color="primary" size="small" fullWidth onClick={() => handleEditDevice(device)} startIcon={<Edit />}>Edit</Button>
                                                    <Button variant="outlined" color="error" size="small" fullWidth onClick={() => handleDeleteDevice(device.deviceuuid)} startIcon={<Delete />}>Delete</Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <center>
                                    No {secureSessionStorage.getItem("DeviceName")} Found
                                </center>
                            )
                        }

                    </CardContent>
                </Card>

            </Container>
        </div>
    )
}

export default ManageDevice
