import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import {
    Delete,
    Edit,
    FirstPage,
    Home,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage
} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import swal from "sweetalert";
import secureSessionStorage from "../../crypto";

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


// ----------------------------------------------------------------------

const ManageCompany = () => {

    const [company, setCompany] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(null);

    const history = useHistory();

    const loadCompanyList = () => {
        setLoading(true);
        var tenantId = secureSessionStorage.getItem("UUID");
        setLoading(true);
        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/0/getCompanyDetails")
            .then((response) => {
                console.log(response.data);
                if (response.data.companies != undefined) {
                    setCompany(response.data.companies);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setCompany([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setCompany([]);
            })
    }

    useEffect(() => {
        loadCompanyList();
    }, [])

    const filteredCompanies = company.filter((companies) =>
        companies.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - company.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    // const handleDeleteCompany = (companyId) => {
    //     swal({
    //         title: "Are you sure?",
    //         text: "You won't be able to revert this!!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     })
    //         .then((willDelete) => {
    //             if (willDelete) {
    //                 var tenantId = secureSessionStorage.getItem("tenantId");

    //                 ApiCalls.delete("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/deleteCompany")
    //                     .then((response) => {
    //                         console.log(response.data);
    //                         if (response.data.status.message === "Success") {
    //                             swal("Deleted Successfully!", {
    //                                 icon: "success",
    //                             });
    //                             loadCompanyList();
    //                         }
    //                     });
    //             }
    //         });
    // }

    const handleDeleteCompany = (companyId) => {
        ApiCalls.getdetails("company/" + companyId + "/checkForConnectedPatientBeds")
            .then((response) => {
                console.log(response.data);
                if (response.data === "active") {
                    swal({
                        title: "Cannot Delete",
                        text: `This ${secureSessionStorage.getItem(
                            "CompanyName"
                        )} cannot be deleted at the moment as there are ${secureSessionStorage.getItem(
                            "PatientName"
                        )} currently admitted.`,
                        icon: "warning",
                        buttons: {
                            ok: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "btn-danger",
                                closeModal: true,
                            },
                        },
                        dangerMode: true,
                    });
                } else {
                    swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                var tenantId = secureSessionStorage.getItem("tenantId");
                                var tenantUserId = secureSessionStorage.getItem("tenantMailId");

                                ApiCalls.delete(
                                    `organizations/0/tenants/${tenantId}/companies/${companyId}/deleteCompany?tenantUserId=${tenantUserId}`
                                )
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.status.details === "Delete Details Successfully") {
                                            swal("Deleted Successfully!", {
                                                icon: "success",
                                            });
                                            loadCompanyList();
                                        }
                                    });
                            }
                        });
                }
            })

    }

    const handleEditCompany = (companyId) => {
        secureSessionStorage.setItem("companyIdToEdit", companyId);
        setRedirectToEdit(true);
    }

    if (redirectToEdit) {
        return (<Redirect to={'/editcompany'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/addcustomer'} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 15 }} maxWidth="lg">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label={"Manage " + secureSessionStorage.getItem("CompanyName")} />
                    </Breadcrumbs>

                    <Button variant="contained" onClick={() => setRedirectToForm(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New {secureSessionStorage.getItem("CompanyName")}
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {secureSessionStorage.getItem("CompanyName")} List
                        </Typography>}
                        action={
                            <TextField
                                label={"Search " + secureSessionStorage.getItem("CompanyName")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredCompanies.length > 0 ? (
                                <Grid container spacing={3}>
                                    {
                                        filteredCompanies.map((company) => (
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Card sx={{
                                                    minWidth: 300,
                                                    boxShadow: 3,
                                                    backgroundColor: "#F4F6F8",
                                                    '&:hover': {
                                                        border: '2px solid #D1E9FC',
                                                        boxShadow: 'none'
                                                    }
                                                }} key={company.companyuuid}>
                                                    <CardHeader
                                                        avatar={
                                                            company.image == undefined ?
                                                                <Avatar
                                                                    sx={{ width: 75, height: 75 }}
                                                                >
                                                                    <span role="img" aria-label="hospital">
                                                                        🏥
                                                                    </span>
                                                                </Avatar> :
                                                                <Avatar
                                                                    alt={company.name}
                                                                    src={company.image}
                                                                    sx={{ width: 75, height: 75 }}
                                                                />
                                                        }
                                                    />
                                                    <CardContent>
                                                        <Box sx={{ mt: 2, mb: 2 }}>
                                                            <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                                                {company.name}
                                                            </Typography>

                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                {company.email}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Phone
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                    {company.phone}
                                                                </Typography>
                                                            </Box>

                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Location
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                                                                    {company.address && Object.keys(company.address).length > 0 ? (
                                                                        <>
                                                                            {company.address.city && company.address.state && company.address.country ? (
                                                                                ` ${company.address.state}, ${company.address.country}`
                                                                            ) : (
                                                                                <>
                                                                                    {company.address.city && company.address.state ? (
                                                                                        `${company.address.city}, ${company.address.state}`
                                                                                    ) : (
                                                                                        <>
                                                                                            {company.address.city || ""}
                                                                                            {company.address.city && company.address.state && company.address.country ? ", " : ""}
                                                                                            {company.address.state || ""}
                                                                                            {company.address.state && company.address.country ? ", " : ""}
                                                                                            {company.address.country || ""}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        "Not Provided"
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                    </CardContent>
                                                    <CardActions>
                                                        <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleEditCompany(company.companyuuid)} startIcon={<Edit />}>Edit</Button>
                                                        <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleDeleteCompany(company.companyuuid)} startIcon={<Delete />}>Delete</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            ) : <center>
                                No {secureSessionStorage.getItem("CompanyName")} found
                            </center>
                        }
                    </CardContent>
                </Card>

            </Container>
        </div >
    )
}

export default ManageCompany
