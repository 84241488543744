import React, { useCallback, useEffect, useRef, useState } from "react";

//mui-components
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";
import { emphasize, styled } from '@mui/material/styles';

//internals

//externals
import { useDropzone } from "react-dropzone";
import swal from "sweetalert";
import Iconify from "../../components/iconify";

//services
import secureSessionStorage from "../../crypto";
import ApiCalls from "../../services/try";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const DropZoneWrapper = styled(Box)(({ theme }) => ({
    border: `2px dashed ${theme.palette.grey[400]}`,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
        borderColor: theme.palette.primary.main,
    },
}));

const AddSignatureDialog = ({ open, onClose, onSignatureUpload }) => {
    const [file, setFile] = useState(null);
    const [signatureExists, setSignatureExists] = useState(false);
    const [signatureUrl, setSignatureUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const uuid = secureSessionStorage.getItem("UUID");
    const isCheckingSignature = useRef(false);

    const fetchSignature = async (filePath) => {
        try {
            const response = await ApiCalls.viewFile("viewFile", { filePath });
            if (response.data instanceof Blob) {
                const url = window.URL.createObjectURL(response.data);
                setSignatureUrl(url);
            } else {
                console.error("Unexpected response data type:", typeof response.data);
            }
        } catch (error) {
            console.error(`Error fetching signature file from ${filePath}:`, error);
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        const checkForSignature = async () => {
            if (isCheckingSignature.current) return;
            isCheckingSignature.current = true;

            const extensions = ['.png', '.jpg', '.jpeg'];

            for (const ext of extensions) {
                const filePath = `./uploads/Signatures/${uuid}${ext}`;

                try {
                    const response = await ApiCalls.viewFile("viewFile", { filePath });
                    if (response.data instanceof Blob) {
                        const url = window.URL.createObjectURL(response.data);
                        setSignatureExists(true);
                        setSignatureUrl(url);
                        setLoading(false);
                        return;
                    } else {
                        console.error("Unexpected response data type:", typeof response.data);
                    }
                } catch (error) {
                    console.error(`Error checking signature with extension ${ext}:`, error);
                }
            }

            setSignatureExists(false);
            setLoading(false);
        };

        checkForSignature();

        return () => {
            if (signatureUrl) {
                URL.revokeObjectURL(signatureUrl);
            }
        };
    }, [signatureUrl, uuid]);

    const onDrop = useCallback((acceptedFiles) => {
        const uploadedFile = acceptedFiles[0];
        setFile(uploadedFile);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "image/*": [".png", ".jpeg", ".jpg"],
        },
        maxFiles: 1,
    });

    const handleFileUpload = () => {
        if (!file) {
            swal("Error", "Please select a signature image to upload.", "error");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        ApiCalls.storeSign(`user/${uuid}/uploadSignature`, formData)
            .then((response) => {
                if (response.data.status.message === "Success") {
                    swal("Success", "Signature uploaded successfully!", "success");
                    onSignatureUpload(true); // Notify parent
                }
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
                swal("Error", "Failed to upload signature. Please try again.", "error");
                onSignatureUpload(false); // Notify parent of failure
            });
    };
    
    const handleCancel = () => {
        setFile(null);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add Your Signature</DialogTitle>
            <DialogContent>
                {loading ? (
                    <>
                        <CircularProgress />
                        <Typography variant="body1" sx={{ mt: 2 }}>Checking for signature...</Typography>
                    </>
                ) : signatureExists ? (
                    <>
                        <Typography variant="h6" sx={{ mb: 2 }}>Signature already added</Typography>
                        <img src={signatureUrl} alt="Signature" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                    </>
                ) : (
                    <>
                        <DropZoneWrapper {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!file ? (
                                <>
                                    <Iconify icon={"fluent:signature-16-filled"} width={40} height={40} />
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Drag 'n' drop a signature image here, or click to select
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Selected file: {file.name}
                                </Typography>
                            )}
                        </DropZoneWrapper>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onMouseDown={handleMouseDown} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onMouseDown={handleMouseDown} onClick={handleFileUpload} disabled={!file}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSignatureDialog;