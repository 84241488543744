import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

//components
import Iconify from '../../components/iconify/Iconify';
import Preview from '../../layout/Preview';

//mui-components
import {
    Delete,
    Edit,
    FirstPage,
    Home,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage
} from '@mui/icons-material';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { emphasize, styled, useTheme } from '@mui/material/styles';

//services
import ApiCalls from "../../services/try";

//externals
import swal from 'sweetalert';
import secureSessionStorage from "../../crypto";

// ----------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// ----------------------------------------------------------------------

const ManagePhysician = () => {

    const [physician, setPhysician] = useState([]);
    const [loading, setLoading] = useState(false);
    const [redirectToForm, setRedirectToForm] = useState(false);
    const [redirectToEdit, setRedirectToEdit] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [roleName, setRoleName] = useState("");

    const loadPhysicianList = () => {
        setLoading(true);
        var rolename = secureSessionStorage.getItem("rolename");
        var companyId = 0;
        var tenantId = 0;
        if (rolename === "Tenant") {
            tenantId = secureSessionStorage.getItem("UUID");
            companyId = 0;
        }
        if (rolename === "Company") {
            tenantId = secureSessionStorage.getItem("tenantId");
            companyId = secureSessionStorage.getItem("UUID");
        }

        ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians")
            .then((response) => {
                console.log(response.data);
                if (response.data.physicians != undefined) {
                    console.log(response.data.physicians.address)
                    setPhysician(response.data.physicians);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setPhysician([]);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                setPhysician([]);
            })
    }

    useEffect(() => {
        var rolename = secureSessionStorage.getItem("rolename");
        setRoleName(rolename);
        loadPhysicianList();
    }, [])

    const filteredPhysicians = physician.filter((physicians) =>
        physicians.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        physicians.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleMouseDown = (e) => {
        e.preventDefault();
    }

    const handleDeletePhysician = (physicianId) => {
        ApiCalls.getdetails("physician/" + physicianId + "/checkForAssignedPatients")
            .then((response) => {
                console.log(response.data);
                if (response.data === "active") {
                    swal({
                        title: "Cannot Delete",
                        text: `Deletion is not allowed because ${secureSessionStorage.getItem("PhysicianName")} has allocated ${secureSessionStorage.getItem("PatientName")}.`,
                        icon: "warning",
                        buttons: {
                            ok: {
                                text: "OK",
                                value: true,
                                visible: true,
                                className: "btn-danger",
                                closeModal: true,
                            },
                        },
                        dangerMode: true,
                    });
                } else {
                    swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    })
                        .then((willDelete) => {
                            if (willDelete) {
                                var tenantId = secureSessionStorage.getItem("tenantId");
                                var companyId = secureSessionStorage.getItem("companyId");
                                var companyUserId = secureSessionStorage.getItem("userid")

                                ApiCalls.delete(
                                    `organizations/0/tenants/${tenantId}/physicians/${physicianId}/deletephysician?companyUserId=${companyUserId}`
                                )
                                    .then((response) => {
                                        console.log(response.data);
                                        if (response.data.status.message === "Success") {
                                            swal("Deleted Successfully!", {
                                                icon: "success",
                                            });
                                            loadPhysicianList();
                                        }
                                    });
                            }
                        });
                }
            })
    }

    const handleEditPhysician = (physicianId) => {
        secureSessionStorage.setItem("physicianIdToEdit", physicianId);
        setRedirectToEdit(true);
    }

    if (redirectToEdit) {
        return (<Redirect to={'/editphysician'} />)
    }

    if (redirectToForm) {
        return (<Redirect to={'/registertherapist'} />)
    }

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>


                        <StyledBreadcrumb label={"Manage " + secureSessionStorage.getItem("PhysicianName")} />
                    </Breadcrumbs>
                    <Button sx={{
                        display: secureSessionStorage.getItem("rolename") === "Company" ? "" : "none"
                    }} variant="contained" onClick={() => setRedirectToForm(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
                        New {secureSessionStorage.getItem("PhysicianName")}
                    </Button>
                </Stack>

                <Card>
                    <CardHeader
                        title={<Typography variant="h4" gutterBottom>
                            {secureSessionStorage.getItem("PhysicianName")} List
                        </Typography>}
                        action={
                            <TextField
                                label={"Search " + secureSessionStorage.getItem("PhysicianName")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        }
                    />
                    <CardContent>
                        {
                            loading ? (
                                <center>
                                    <CircularProgress />
                                </center>
                            ) : filteredPhysicians.length > 0 ? (
                                <Grid container spacing={3}>
                                    {
                                        filteredPhysicians.map((physician) => (
                                            <Grid item xs={12} sm={6} md={4}>
                                                <Card
                                                    sx={{
                                                        minWidth: 300,
                                                        boxShadow: 3,
                                                        backgroundColor: "#F4F6F8",
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        '&:hover': {
                                                            border: '1px solid #D1E9FC',
                                                            boxShadow: 'none'
                                                        }
                                                    }}
                                                    key={physician.physicianuuid}
                                                >
                                                    <CardHeader
                                                        avatar={<Avatar
                                                            alt={physician.firstName + " " + physician.lastName}
                                                            src={physician.image}
                                                            sx={{ width: 75, height: 75 }}
                                                        />}
                                                    />
                                                    <CardContent sx={{ flexGrow: 1 }}>
                                                        <Box sx={{ mt: 2, mb: 2 }}>
                                                            <Typography variant="h5" sx={{ color: 'text.primary' }}>
                                                                {`${physician.firstName || ''} ${physician.lastName || ''}`.trim()}
                                                            </Typography>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                {physician.email}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                Gender
                                                            </Typography>
                                                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                {physician.gender || 'Not specified'}
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Phone
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                                                    {physician.phone || 'Not provided'}
                                                                </Typography>
                                                            </Box>

                                                            <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                                                                    Location
                                                                </Typography>
                                                                <Typography variant="subtitle2" sx={{ color: "text.primary", maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {physician.address && Object.keys(physician.address).length > 0 ? (
                                                                        <>
                                                                            {physician.address.city && physician.address.state && physician.address.country ? (
                                                                                ` ${physician.address.state}, ${physician.address.country}`
                                                                            ) : (
                                                                                <>
                                                                                    {physician.address.city && physician.address.state ? (
                                                                                        `${physician.address.city}, ${physician.address.state}`
                                                                                    ) : (
                                                                                        <>
                                                                                            {physician.address.city || ""}
                                                                                            {physician.address.city && physician.address.state && physician.address.country ? ", " : ""}
                                                                                            {physician.address.state || ""}
                                                                                            {physician.address.state && physician.address.country ? ", " : ""}
                                                                                            {physician.address.country || ""}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        "Not Provided"
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button variant="outlined" color="primary" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleEditPhysician(physician.physicianuuid)} startIcon={<Edit />}>Edit</Button>
                                                        <Button variant="outlined" color="error" size="small" fullWidth onMouseDown={handleMouseDown} onClick={() => handleDeletePhysician(physician.physicianuuid)} startIcon={<Delete />}>Delete</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            ) : <center>
                                No {secureSessionStorage.getItem("PhysicianName")} found
                            </center>
                        }
                    </CardContent>
                </Card>
            </Container>

        </div>
    )
}

export default ManagePhysician
