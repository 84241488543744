import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';

//mui-components
import { Home } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    CircularProgress,
    Container,
    Stack,
    Typography
} from "@mui/material";
import { emphasize, styled } from '@mui/material/styles';

//internals
import Preview from "../../layout/Preview";

//externals
import { useDropzone } from "react-dropzone";
import swal from "sweetalert";
import Iconify from "../../components/iconify";

//services
import secureSessionStorage from "../../crypto";
import ApiCalls from "../../services/try";

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const DropZoneWrapper = styled(Box)(({ theme }) => ({
    border: `2px dashed ${theme.palette.grey[400]}`,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
        borderColor: theme.palette.primary.main,
    },
}));

//-------------------------------------------------------------------------------

const AddSignaturePage = () => {
    const [file, setFile] = useState(null);
    const [signatureExists, setSignatureExists] = useState(false);
    const [signatureUrl, setSignatureUrl] = useState("");
    const [loading, setLoading] = useState(true); // New state for loading

    const uuid = secureSessionStorage.getItem("UUID");
    const isCheckingSignature = useRef(false); // Track if we are currently checking

    // Function to fetch the signature file from the server
    const fetchSignature = async (filePath) => {
        try {
            const response = await ApiCalls.viewFile("viewFile", { filePath });
            if (response.data instanceof Blob) {
                const url = window.URL.createObjectURL(response.data);
                setSignatureUrl(url); // Set the URL created from the Blob
            } else {
                console.error("Unexpected response data type:", typeof response.data);
            }
        } catch (error) {
            console.error(`Error fetching signature file from ${filePath}:`, error);
        }
    };

    // Check if a signature already exists based on UUID and common image extensions
    useEffect(() => {
        const checkForSignature = async () => {
            if (isCheckingSignature.current) return; // Prevent further execution if already checking
            isCheckingSignature.current = true; // Set flag to true when starting

            const extensions = ['.png', '.jpg', '.jpeg']; // List of possible image extensions

            for (const ext of extensions) {
                const filePath = `./uploads/Signatures/${uuid}${ext}`; // Construct the path with the extension

                try {
                    const response = await ApiCalls.viewFile("viewFile", { filePath });
                    if (response.data instanceof Blob) {
                        const url = window.URL.createObjectURL(response.data);
                        setSignatureExists(true);
                        setSignatureUrl(url); // Set the URL created from the Blob
                        setLoading(false); // Stop loading
                        return; // Exit loop after finding a valid signature
                    } else {
                        console.error("Unexpected response data type:", typeof response.data);
                    }
                } catch (error) {
                    console.error(`Error checking signature with extension ${ext}:`, error);
                }
            }

            // If no file is found
            setSignatureExists(false); // Set to false to show uploader
            setLoading(false); // Stop loading
        };

        checkForSignature();

        // Cleanup function to revoke object URLs
        return () => {
            if (signatureUrl) {
                URL.revokeObjectURL(signatureUrl);
            }
        };
    }, [signatureUrl, uuid]);

    const onDrop = useCallback((acceptedFiles) => {
        const uploadedFile = acceptedFiles[0];
        setFile(uploadedFile);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "image/*": [".png", ".jpeg", ".jpg"],
        },
        maxFiles: 1,
    });

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const handleFileUpload = () => {
        if (!file) {
            swal("Error", "Please select a signature image to upload.", "error");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        // Log the FormData to check if the file is added
        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        ApiCalls.storeSign(`user/${uuid}/uploadSignature`, formData)
            .then((response) => {
                console.log(response.data);
                if (response.data.status.message === "Success") {
                    swal("Success", "Signature uploaded successfully!", "success");
                    setFile(null); // Clear the file after successful upload
                    setSignatureExists(true); // Update to show signature exists

                    // Fetch the uploaded file using the viewFile API
                    const uploadedFilePath = response.data.status.filePath;
                    fetchSignature(uploadedFilePath); // Call the viewFile API to get the actual image Blob
                }
            })
            .catch((error) => {
                console.error("Error uploading file:", error);
                swal("Error", "Failed to upload signature. Please try again.", "error");
            });
    };

    const handleCancel = () => {
        setFile(null); // Clear the selected file
    };

    return (
        <div style={{ display: "flex" }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Add your Signature" />
                    </Breadcrumbs>
                </Stack>

                <Card
                    sx={{
                        px: "50px",
                        pt: "10%",
                        borderRadius: "30px",
                        height: "400px",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 30px",
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {loading ? (
                        <>
                            <CircularProgress />
                            <Typography variant="body1" sx={{ mt: 2 }}>Checking for signature...</Typography>
                        </>
                    ) : signatureExists ? (
                        <>
                            <Typography variant="h6" sx={{ mb: 2 }}>Signature already added</Typography>
                            <img src={signatureUrl} alt="Signature" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </>
                    ) : (
                        <>
                            <DropZoneWrapper {...getRootProps()}>
                                <input {...getInputProps()} />
                                {!file ? (
                                    <>
                                        <Iconify icon={"fluent:signature-16-filled"} width={40} height={40} />
                                        <Typography variant="body1" sx={{ mt: 2 }}>
                                            Drag 'n' drop a signature image here, or click to select
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Selected file: {file.name}
                                    </Typography>
                                )}
                            </DropZoneWrapper>

                            {/* Save and Cancel Buttons */}
                            <Stack
                                direction="row"
                                justifyContent="flex-end"  // Aligns buttons to the right
                                spacing={2}  // Adds spacing between the buttons
                                sx={{ mt: 2 }}
                            >
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFileUpload}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </>
                    )}
                </Card>
            </Container>
        </div>
    );
};

export default AddSignaturePage;
