import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

// @mui-components
import { ChevronLeftRounded, ChevronRightRounded, Close, InfoRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';

// components
import Iconify from '../../components/iconify';

// sections
import {
  CountCard,
  MeasurementList,
} from './components';

//services
import ApiCalls from "../../services/try";

//internals
import UserImg from "../../assets/images/avatars/user.png";
import DeviceImg from "../../assets/images/devices.png";
import Preview from '../../layout/Preview';

//externals
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import 'chartjs-plugin-streaming';
import 'chartjs-plugin-zoom';
import moment from 'moment';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import secureSessionStorage from "../../crypto";


// Import and register the line controller
import { CategoryScale, LineController, LineElement, LinearScale, PointElement } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale);

// ----------------------------------------------------------------------

const MaterialArrow = ({ className, onClick, direction }) => {
  const arrowStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    [direction]: direction === "prev" ? "-35px" : "auto",
    right: direction === "next" ? "0" : "10px"
  };

  return (
    <div className={className} onClick={onClick} style={arrowStyle}>
      {direction === "prev" ? (
        <ChevronLeftRounded color="primary" fontSize="large" />
      ) : (
        <ChevronRightRounded color="primary" fontSize="large" />
      )}
    </div>
  );
};

const CHART_HEIGHT = 270;
const LEGEND_HEIGHT = 65;

const StyledCard = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function Dashboard() {
  const [companyCount, setCompanyCount] = useState(0);
  const [physicianCount, setPhysicianCount] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);
  const [roleId, setRoleId] = useState('');

  const [selectedPatient, setSelectedPatient] = useState(null);

  const [showPatient, setShowPatient] = useState(false);
  const [showMeasurements, setShowMeasurements] = useState(false);
  const [companyRedirect, setCompanyRedirect] = useState(false);
  const [physicianRedirect, setPhysicianRedirect] = useState(false);
  const [patientRedirect, setPatientRedirect] = useState(false);
  const [deviceRedirect, setDeviceRedirect] = useState(false);
  const [loadDevice, setLoadDevice] = useState(false);
  const [loadPatient, setLoadPatient] = useState(true);
  const [openPatientInfo, setOpenPatientInfo] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [redirecttoDeviceView, setRedirecttoDeviceView] = useState(false);
  const [redirecttoDeviceTwin, setRedirecttoDeviceTwin] = useState(false);
  const [companyCountHide, setCompanyCountHide] = useState(false);
  const [physicianCountHide, setPhysicianCountHide] = useState(false);
  const [patientCountHide, setPatientCountHide] = useState(false);
  const [isPatientLogin, setIsPatientLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState("false");
  const [patientTrend, setPatientTrend] = useState("false");

  const [company, setCompany] = useState([]);
  const [physicians, setPhysicians] = useState([]);
  const [patients, setPatients] = useState([]);
  const [device, setDevice] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [assignedPhysician, setAssignedPhysician] = useState([]);
  const [patientprivilege, setPatientPrivilege] = useState([]);


  const [filterOptions, setFilterOptions] = useState([
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'week',
      label: 'One Week',
    },
    {
      value: 'month',
      label: 'One Month',
    },
    {
      value: 'all',
      label: 'All',
    },
    // {
    //   value: 'date',
    //   label: 'Selected Dates',
    // },
    // {
    //   value: 'specificdate',
    //   label: 'Date',
    // },
  ]);

  const [durationFilterOptions, setDurationFilterOptions] = useState([
    { value: "60", label: "Last 1 Hour" },
    { value: "120", label: "Last 2 Hours" },
    { value: "180", label: "Last 3 Hours" },
    { value: "240", label: "Last 4 Hours" },
    { value: "300", label: "Last 5 Hours" },
    { value: "360", label: "Last 6 Hours" },
    { value: "420", label: "Last 7 Hours" },
  ]);

  // Set the default filter to "Today"
  const [selectedDateFilter, setSelectedDateFilter] = useState(
    filterOptions.find((option) => option.value === "today")
  );
  const [effectiveDateFilter, setEffectiveDateFilter] = useState(filterOptions[0]);
  const [selectedParamFilter, setSelectedParamFilter] = useState([]);
  // Set the default filter to "1 hour"
  const [selectedDurationFilter, setSelectedDurationFilter] = useState(
    durationFilterOptions.find((option) => option.value === "60")
  );
  const [paramName, setParamName] = useState('');
  const [pidforChart, setPidForChart] = useState('');
  const [pnameforChart, setPnameForChart] = useState('');
  const [noMeasureText, setNoMeasureText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(secureSessionStorage.getItem("compId") || "");

  const [openDateFilter, setOpenDateFilter] = useState(null);
  const [openParamFilter, setOpenParamFilter] = useState(null);
  const [openDurationFilter, setOpenDurationFilter] = useState(null);

  const sliderRef = useRef(null);
  const theme = useTheme();
  const chartRefreshInterval = useRef(null);

  const SkeletonCard = () => {
    return (
      <Card sx={{ maxWidth: 200, borderRadius: 2, boxShadow: 5, marginBottom: 5 }}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Skeleton sx={{ height: 155 }} animation="wave" variant="rectangular" />

        <CardActions disableSpacing>
        </CardActions>
      </Card>
    );
  };

  const loadingCards = Array.from({ length: 2 }, (_, index) => <SkeletonCard key={index} />);


  {/* -----------------------Slider Settings----------------------------------------------------------------------------------- */ }


  const settings = {
    // sets custom arrow components
    prevArrow: <MaterialArrow direction="prev" />,
    nextArrow: <MaterialArrow direction="next" />,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const refreshData = (companyId) => {
    getPatient(companyId);
    getPhysician(companyId);
    getDevice(companyId);
  };

  const handleCompanySelect = (selectedCompany) => {
    setSelectedCompany(selectedCompany);
    refreshData(selectedCompany); // Call the refreshData function to update the data
  };

  useEffect(() => {
    refreshData(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {

    var compid = "";
    var compname = "";
    var physid = "";
    var physname = "";

    const roleId = secureSessionStorage.getItem("roleId");
    setRoleId(roleId)
    const userId = secureSessionStorage.getItem("tenantMailId");

    getRoleBasedMenu(userId, roleId)

    switch (roleId) {
      case "3":
        console.log("tenant login")
        if (secureSessionStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureSessionStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }

        setPatientCountHide(true);
        setIsPatientLogin(false);
        break;

      case "4":
        console.log("company login")
        if (secureSessionStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureSessionStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }
        setPatientCountHide(false);
        setIsPatientLogin(false);
        break;

      case "6":
        console.log("physician login")
        if (secureSessionStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureSessionStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }
        setPatientCountHide(false);
        setIsPatientLogin(false);
        break;

      case "8":
        console.log("patient login")
        if (secureSessionStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureSessionStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }
        setPatientCountHide(true);
        setIsPatientLogin(true);
        break;

      default:
        break;
    }

    if (secureSessionStorage.getItem("compId") === null) {
      compid = "0";
      compname = "All";
      secureSessionStorage.setItem("compId", compid);
      secureSessionStorage.setItem("compName", compname);

    }
    if (secureSessionStorage.getItem("physId") === null) {
      physid = "0";
      physname = "All";
      secureSessionStorage.setItem("physId", physid);
      secureSessionStorage.setItem("physName", physname);

    }
    if (secureSessionStorage.getItem("compId") !== undefined) {
      compid = secureSessionStorage.getItem("compId");
    }
    else {
      compid = "0";
    }
    if (secureSessionStorage.getItem("compName") !== undefined) {
      compname = secureSessionStorage.getItem("compName");
    }
    else {
      compname = "All";
    }
    // ----------------------------------------------------------------------

    if (secureSessionStorage.getItem("physId") !== undefined && secureSessionStorage.getItem("physId") !== null) {
      physid = secureSessionStorage.getItem("physId");
    }
    else {
      physid = "0";
    }
    if (secureSessionStorage.getItem("physName") !== undefined) {
      compname = secureSessionStorage.getItem("physName");
    }
    else {
      physname = "All";
    }
    secureSessionStorage.setItem("physId", physid);
    secureSessionStorage.setItem("physName", physname);



    getPatient(physid, compid);
    getCompany();
    getPhysician();
    getDevice();

    return () => {
      clearInterval(chartRefreshInterval.current);
    };
  }, [])

  const getRoleBasedMenu = (user, roleId) => {
    ApiCalls.getRoleBasedMenu(user, roleId)
      .then(function (response) {
        console.log(response.data);
        const menuArray = response.data;

        const hasManageCompany = menuArray.some(item => item.url === '/managecustomer');
        const hasManagePhysician = menuArray.some(item => item.url === '/managetherapist');

        if (!hasManageCompany) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }

        if (!hasManagePhysician) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }

      })
  }


  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  {/* -----------------------Company Details Listing API----------------------------------------------------------------------------------- */ }


  const getCompany = () => {
    var tenanitid = secureSessionStorage.getItem("UUID");
    var compcount = 0;
    ApiCalls.getdetails("organizations/0/tenants/" + tenanitid + "/getCompanyNames")
      .then((result) => {
        if (result.data.companies != undefined) {
          for (var i = 0; i < result.data.companies.length; i++) {
            if (result.data.companies[i].companyuuid !== "0") {
              compcount += 1;
            }
          }
          if (result.data.companies !== undefined) {
            setCompanyCount(compcount)
            const options = result.data.companies.map(d => ({
              "value": d.companyuuid,
              "label": d.name
            }))
            setCompany(options);
          }
          else {
            setCompanyCount(0)
            const options = {
              "value": "0",
              "label": "None"
            }
            setCompany(options);
          }
        }


      })
  }

  {/* -----------------------Physician Details Listing API----------------------------------------------------------------------------------- */ }


  const getPhysician = () => {
    var uuid = secureSessionStorage.getItem("UUID");
    var rolename = secureSessionStorage.getItem("rolename");
    var companyId = "";
    var tenantId = "";
    if (rolename === "Tenant") {
      tenantId = uuid;
      companyId = secureSessionStorage.getItem("compId");
    }
    if (rolename === "Company") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = uuid;
    } var physiciancount = 0;
    ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/getphysiciannames")
      .then((result) => {
        for (var i = 0; i < result.data.physicians.length; i++) {
          if (result.data.physicians[i].physicianuuid !== "0") {
            physiciancount += 1;
          }
        }
        if (result.data.physicians !== undefined) {
          setPhysicianCount(physiciancount);
          const options = result.data.physicians.map(d => ({
            "value": "0",
            "label": "All"
          }, {
            "value": d.physicianuuid,
            "label": d.lastName === "All" ? "All" : d.firstName + " " + d.lastName
          }))
          setPhysicians(options)
        }
        else {
          setPatientCount(0)
        }

      })
      .catch(e => {
        setPhysicianCount(0)
      })
  }
  {/* -----------------------Patient Details Listing API----------------------------------------------------------------------------------- */ }

  const getPatient = () => {
    let companyId = secureSessionStorage.getItem("compId");
    let tenantId = secureSessionStorage.getItem("tenantId");
    let physicianId = "0";
    const rolename = secureSessionStorage.getItem("rolename");

    switch (rolename) {
      case "Tenant":
        tenantId = secureSessionStorage.getItem("UUID");
        companyId = secureSessionStorage.getItem("compId");
        physicianId = "0";
        break;
      case "Company":
        tenantId = secureSessionStorage.getItem("tenantId");
        companyId = secureSessionStorage.getItem("UUID");
        physicianId = "0";
        break;
      case "Physician":
        tenantId = secureSessionStorage.getItem("tenantId");
        companyId = secureSessionStorage.getItem("companyId");
        physicianId = secureSessionStorage.getItem("UUID");
        break;
      default:
        break;
    }

    var roleId = secureSessionStorage.getItem("roleId");

    ApiCalls.getdetails("roleId/" + roleId + "/getpatientprivilegedetails")
      .then((response) => {
        console.log(response.data);
        if (response.data.patientprivilege != undefined) {
          const patientInfo = response.data.patientprivilege[0].patientInfo;
          const patientTrend = response.data.patientprivilege[0].patientTrend;
          setPatientPrivilege(response.data.patientprivilege);
          console.log("PatientInfo: " + patientInfo);
          console.log("patientTrend: " + patientTrend);
          setPatientInfo(patientInfo);
          setPatientTrend(patientTrend);

          // Check patientInfo before making the second API call
          if (patientInfo === "true") {
            // Proceed with the second API call
            ApiCalls.getdetails("organizations/0/tenants/" + tenantId + "/companies/" + companyId + "/physicians/" + physicianId + "/patients?")
              .then(result => {
                setLoadPatient(false); // Loading is complete, regardless of whether data is present or not

                if (result.data.patient !== undefined && result.data.patient.length > 0) {
                  setPatients(result.data.patient);
                  setShowPatient(true);
                  setPatientCount(result.data.patient.length);
                } else {
                  setPatients([]); // No data available
                  setShowPatient(false);
                  setPatientCount(0);
                }
              }).catch(e => {
                setPatients([]);
                setPatientCount(0);
                setShowPatient(false);
                setLoadPatient(false);
              });
          } else {
            // Set the message if patientInfo is false
            console.log("No permission to view patient details");
            setPatients([]);
            setPatientCount(0);
            setShowPatient(false);
            setLoadPatient(false);
          }
        } else {
          setPatientPrivilege([]);
        }
      });
  }


  {/* -----------------------Device Details Listing API----------------------------------------------------------------------------------- */ }

  const getDevice = () => {
    setLoadDevice(true);
    var tenantId = secureSessionStorage.getItem("tenantId");
    var companyId = secureSessionStorage.getItem("compId");
    var physicianId = "";
    var rolename = secureSessionStorage.getItem("rolename");
    if (rolename === "Tenant") {
      tenantId = secureSessionStorage.getItem("UUID");
      companyId = "0";
      physicianId = "0";
    } else if (rolename === "Company") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = secureSessionStorage.getItem("UUID");
      physicianId = "0";
    } else if (rolename === "Physician") {
      tenantId = secureSessionStorage.getItem("tenantId");
      companyId = secureSessionStorage.getItem("compId");
      physicianId = secureSessionStorage.getItem("UUID");
    }
    ApiCalls.getdetails("PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice")
      .then((result) => {
        if (result.data.device !== undefined) {
          setDevice(result.data.device);
          setLoadDevice(false);
          setDeviceCount(result.data.device.length)
        }
        else {
          setDevice([]);
          setLoadDevice(false);
          setDeviceCount(0)
        }

      }).catch(e => {
        setLoadDevice(false);
        setDevice([]);
        setDeviceCount(0)
      });
  }

  {/* -----------------------Handle Click Patient to view Chart & Data----------------------------------------------------------------------------------- */ }


  const handleClickPatient = (patient) => {
    setSelectedDateFilter(filterOptions[0])
    setSelectedParamFilter([]);
    setPidForChart(patient.patientuuid)
    var patientId = patient.patientuuid;
    var patientName = patient.patientName;

    if (patientId !== "" && patientId !== null) {
      setShowMeasurements(true);
    } else {
      setShowMeasurements(false);
    }

    getParameterName(patientId);
    var patientName = (patient.firstName || '') + (patient.lastName ? ` ${patient.lastName}` : '');
    setPnameForChart(patientName);

    secureSessionStorage.setItem("ChartPId", patientId);
    setNoMeasureText("");
    loadMeasurements(patientId);

    const interval = setInterval(() => {
      loadMeasurements(patientId);
      chartMeasurements(patientId, selectedParamFilter.label, selectedDateFilter.value, selectedDurationFilter.value);
    }, 5 * 60 * 1000); // 5 minutes in milliseconds

  }

  {/* -----------------------Parameter Details Listing API----------------------------------------------------------------------------------- */ }


  const getParameterName = (patientId) => {
    ApiCalls.getdetails("patientId/" + patientId + "/getmeasurementparameter")
      .then((res) => {
        console.log(res.data);
        if (res.data.measure !== undefined) {
          setParamName(res.data.measure[0].paramName)
          const options = res.data.measure.map(d => ({
            "label": d.paramName,
            "value": d.paramid
          }))
          var dateFilter = 'today';
          var durationFilter = 1;
          chartMeasurements(patientId, res.data.measure[0].paramName, dateFilter, durationFilter);
          setParameters(options);
          setSelectedParamFilter(options[0])
        }
      })
  }

  {/* -----------------------Measurement Details Listing API----------------------------------------------------------------------------------- */ }


  const loadMeasurements = (patientId) => {
    setLoading(true);
    const parameterName = 'all';
    const dateFilter = 'today';
    const durationFilter = 1; // 1 hour

    ApiCalls.getdetails(`patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/${durationFilter}`)
      .then((res) => {
        if (res.data.measurement !== undefined && res.data.measurement.length > 0) {
          setMeasurements(res.data.measurement);
          setNoMeasureText("");
          setShowMeasurements(true);
          setLoading(false);
        } else {
          // If no data in the last hour, fetch all data
          loadMeasurementDetailsById(patientId, true);
        }
      })
      .catch((error) => {
        // If there's an error, fetch all data
        loadMeasurementDetailsById(patientId, true);
      });
  };

  const loadMeasurementDetailsById = (patientId, changeFilter = false) => {
    const paramName = "all";
    console.log("going to get all data...");

    let newEffectiveDateFilter;
    if (changeFilter) {
      newEffectiveDateFilter = {
        value: "all",
        label: "All"
      };
    } else {
      newEffectiveDateFilter = selectedDateFilter;
    }

    setEffectiveDateFilter(newEffectiveDateFilter);

    console.log("Effective date filter:", newEffectiveDateFilter.value);

    ApiCalls.getdetails(`patient/${patientId}/parameter/${paramName}/${newEffectiveDateFilter.value}/getmeasurementwithfilter/0/0/0`)
      .then((response) => {
        if (response.data.measurement !== undefined && response.data.measurement.length > 0) {
          setMeasurements(response.data.measurement);
          setNoMeasureText("");
          setShowMeasurements(true);
        } else {
          setMeasurements([]);
          setShowMeasurements(false);
          setNoMeasureText('No Measurements For');
          setPidForChart('');
        }
      })
      .catch((error) => {
        setMeasurements([]);
        setShowMeasurements(false);
        setNoMeasureText('Error fetching measurements');
        setPidForChart('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  {/* -----------------------Chart Data Listing API----------------------------------------------------------------------------------- */ }

  const chartMeasurements = async (patientId, parameterName, dateFilter, durationFilter) => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    let labelData = [];
    let chartData = [];

    try {
      const res = await ApiCalls.getdetails(`patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/${durationFilter}`);
      if (res.data.measurement) {
        for (const dataObj of res.data.measurement) {
          const dateStr = dataObj.dateOfMeasurementinIST;

          let formattedDateStr;
          if (dateFilter === 'today') {
            // Extract only the time part in hh:mm:ss AM/PM format
            const timePart = dateStr.split(" ")[1]; // "04:08:11.508"
            const amPm = dateStr.split(" ")[2]; // "PM"

            // Remove milliseconds and combine time with AM/PM
            formattedDateStr = timePart.split(".")[0] + " " + amPm;
          } else {
            // For full date and time, remove milliseconds from the time part
            const datePart = dateStr.split(" ")[0]; // "06-11-2024"
            const timePart = dateStr.split(" ")[1]; // "04:08:11.508"
            const amPm = dateStr.split(" ")[2]; // "PM"

            // Remove milliseconds from the time part
            formattedDateStr = datePart + " " + timePart.split(".")[0] + " " + amPm;
          }

          labelData.push(formattedDateStr);
          chartData.push(parseFloat(dataObj.readingValues));
        }
      }
    } catch (err) {
      console.error('Error fetching measurements:', err);
    }

    if (chartRef.current) {
      chartRef.current.data.labels = labelData.length > 0 ? labelData : ['No data available'];
      chartRef.current.data.datasets = chartData.length > 0
        ? [
          {
            label: parameterName + ' () Reading Level',
            data: chartData,
            borderColor: '#6ff9ff', // Brighter/high contrast version of #40e0d0
            // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
            // fill: true,
            borderWidth: 1.5,
            tension: 0.5,
            pointStyle: 'circle', // Set the point style to 'circle'
            pointRadius: 0, // Set the point radius to 0 to hide the points
          },
        ]
        : [
          {
            label: parameterName + ' () Reading Level',
            data: [0],
            borderColor: '#6ff9ff', // Brighter/high contrast version of #40e0d0
            // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
            // fill: true,
            borderWidth: 1.5,
            tension: 0.5,
          },
        ];

      chartRef.current.update();
    } else {
      chartRef.current = new Chart(canvas, {
        type: 'line',
        data: {
          labels: labelData.length > 0 ? labelData : ['No data available'],
          datasets: chartData.length > 0
            ? [
              {
                label: parameterName + ' () Reading Level',
                data: chartData,
                borderColor: '#6ff9ff', // Brighter/high contrast version of #40e0d0
                // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
                // fill: true,
                borderWidth: 1.5,
                tension: 0.5,
                pointStyle: 'circle', // Set the point style to 'circle'
                pointRadius: 0, // Set the point radius to 0 to hide the points
              },
            ]
            : [
              {
                label: parameterName + ' () Reading Level',
                data: [0],
                borderColor: '#6ff9ff', // Brighter/high contrast version of #40e0d0
                // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
                // fill: true,
                borderWidth: 1.5,
                tension: 0.5,
              },
            ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }

    // Clear any existing interval before starting a new one
    if (chartRefreshInterval.current) {
      clearInterval(chartRefreshInterval.current);
    }

    chartRefreshInterval.current = setInterval(async () => {
      const currentTime = moment().format('HH:mm:ss');
      try {
        await chartMeasurements(patientId, parameterName, dateFilter, durationFilter);
        console.log(`after 5 mins... Current Time: ${currentTime}`);
      } catch (err) {
        console.error(err);
      }
    }, 5 * 60 * 1000); // 5 minutes in milliseconds
  };

  const handleOpenDurationFilters = (e) => {
    setOpenDurationFilter(e.currentTarget);
  };

  const handleCloseDurationFilters = () => {
    setOpenDurationFilter(null);
  };

  const handleDurationFilterChange = (filter) => {
    setSelectedDurationFilter(filter);
    chartMeasurements(pidforChart, selectedParamFilter.label, selectedDateFilter.value, filter.value)
    handleCloseDurationFilters();
  }

  const handleOpenDateFilters = (e) => {
    setOpenDateFilter(e.currentTarget);
  }

  const handleCloseDateFilters = () => {
    setOpenDateFilter(null);
  }

  const handleDateFilterChange = (filter) => {
    setSelectedDateFilter(filter);
    chartMeasurements(pidforChart, selectedParamFilter.label, filter.value, selectedDurationFilter.value)
    handleCloseDateFilters();
  }

  const handleOpenParamFilters = (e) => {
    setOpenParamFilter(e.currentTarget);
  };

  const handleCloseParamFilters = () => {
    setOpenParamFilter(null);
  };

  const handleParamFilterChange = (filter) => {
    setParamName(filter.label)
    setSelectedParamFilter(filter);
    chartMeasurements(pidforChart, filter.label, selectedDateFilter.value, selectedDurationFilter.value)
    handleCloseParamFilters();
  }

  const handleClickCompanyCount = () => {
    setCompanyRedirect(true);

  }
  const handleClickPhysicianCount = () => {
    setPhysicianRedirect(true);
  }

  const handleClickPatientCount = () => {
    setPatientRedirect(true);
  }

  const handleClickDeviceCount = () => {
    setDeviceRedirect(true);
  }

  const filteredPatients = patients.filter((patient) => {
    const firstName = patient.firstName === "" || "";
    const lastName = patient.lastName === "" || "";
    return firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lastName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleOpenPatientInfo = (patient) => {
    setSelectedPatient(patient);
    loadAssignedPhysician(patient.patientuuid);
    setOpenPatientInfo(true);
  }

  const loadAssignedPhysician = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getPhysician")
      .then((response) => {
        if (response.data.patient !== undefined) {
          setAssignedPhysician(response.data.patient)
        }
      })
  }

  const handleClosePatientInfo = () => {
    setSelectedPatient(null);
    setOpenPatientInfo(false);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (companyRedirect) {
    return (<Redirect to="/managecustomer" />)
  } else if (physicianRedirect) {
    return (<Redirect to="/managetherapist" />)
  } else if (patientRedirect) {
    return (<Redirect to="/managepatient" />)
  } else if (deviceRedirect && !isPatientLogin) {
    return (<Redirect to="/managedevice" />)
  }

  if (redirecttoDeviceView) {
    return (
      <Redirect to={'/deviceview'} />
    )
  }

  if (redirecttoDeviceTwin) {
    return (
      <Redirect to={'/devicetwin'} />
    )
  }

  const handleMouseDown = (e) => {
    e.preventDefault();
  }

  return (
    <div style={{ display: "flex" }}>
      <Preview onCompanySelect={handleCompanySelect} />
      <Container maxWidth="xl" sx={{ marginTop: 10 }} >
        <Typography variant="h4" sx={{ mb: 5 }}>
          {secureSessionStorage.getItem("LoginUserName") == null ? "Welcome " : "Welcome " + secureSessionStorage.getItem("LoginUserName")}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6} sm={3} md={3} style={{ display: companyCountHide ? 'none' : 'block' }}>
            <CountCard title={"Total " + secureSessionStorage.getItem('CompanyName')} total={companyCount} color="primary" icon={'uis:hospital'} onClick={handleClickCompanyCount} />
          </Grid>

          <Grid item xs={6} sm={3} md={3} style={{ display: physicianCountHide ? 'none' : 'block' }}>
            <CountCard title={"Total " + secureSessionStorage.getItem('PhysicianName')} total={physicianCount} color="primary" icon={'fontisto:doctor'} onClick={handleClickPhysicianCount} />
          </Grid>

          <Grid item xs={6} sm={3} md={3} style={{ display: patientCountHide || patientInfo === "false" ? 'none' : 'block' }}>
            <CountCard
              title={"Total " + secureSessionStorage.getItem('PatientName')}
              total={patientCount}
              color="primary"
              icon={'material-symbols:recent-patient-rounded'}
              onClick={handleClickPatientCount}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <CountCard title={"Total " + secureSessionStorage.getItem('DeviceName')} total={deviceCount} color="primary" icon={'tabler:devices-cog'} onClick={handleClickDeviceCount} />
          </Grid>


          {/* -----------------------Patient Listing----------------------------------------------------------------------------------- */}

          <Grid item xs={12} md={12} lg={12} hidden={patientCountHide || isPatientLogin || patientInfo === "false"}>
            <Card>
              <CardHeader
                title={secureSessionStorage.getItem("PatientName") + " List"}
                action={
                  <TextField
                    fullWidth
                    color='primary'
                    hidden={!showPatient}
                    label={"Search " + secureSessionStorage.getItem("PatientName")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                }
              />
              <CardContent>
                <Typography sx={{ mb: 1.5 }} color="text.secondary" hidden={!showPatient || patientTrend === "false"}>
                  * Click the {secureSessionStorage.getItem("PatientName")} to view the measurement details
                </Typography>
                <center><p style={{ fontWeight: "bold", fontSize: 11, color: "red" }}>{noMeasureText === "" ? " " : noMeasureText + " " + pnameforChart} </p></center>

                <StyledCard dir="ltr">
                  <Slider ref={sliderRef} {...settings}>

                    {
                      loadPatient ? (
                        loadingCards
                      ) : (
                        showPatient ? (
                          filteredPatients.length > 0 ? (
                            filteredPatients.map((patient) => (
                              <Card sx={{ maxWidth: 220, minHeight: 280, border: pidforChart == patient.patientuuid ? 3 : 'none', borderColor: '#76B0F1', borderRadius: 2, boxShadow: 5, mx: 3 }} key={patient.patientuuid}>
                                <CardHeader
                                  titleTypographyProps={{ variant: 'subtitle2' }}
                                  title={
                                    `${patient.firstName ? patient.firstName.charAt(0).toUpperCase() + patient.firstName.slice(1) : ''} ${patient.lastName ? patient.lastName.charAt(0).toUpperCase() + patient.lastName.slice(1) : ''}`
                                  }
                                />
                                <CardMedia
                                  component="img"
                                  height="155"
                                  width="155"
                                  image={
                                    patient.image == undefined ||
                                      patient.image == "null"
                                      ? UserImg
                                      : patient.image
                                  }
                                  alt={patient.firstName + " " + patient.lastName}
                                  onClick={() =>
                                    patientTrend === "true" &&
                                    handleClickPatient(patient)
                                  }
                                  sx={{ objectFit: "contain", cursor: "pointer" }}
                                />
                                <CardActions disableSpacing>
                                  <Tooltip title={secureSessionStorage.getItem("PatientName") + " info"} onClick={() => handleOpenPatientInfo(patient)} onMouseDown={handleMouseDown} >
                                    <IconButton aria-label="info">
                                      <InfoRounded color='primary' />
                                    </IconButton>
                                  </Tooltip>
                                </CardActions>
                              </Card>
                            ))
                          ) : (
                            <Typography variant="body1" align="center">
                              No {secureSessionStorage.getItem("PatientName")} found
                            </Typography>
                          )
                        ) : (
                          <Typography variant="body1" align="center">
                            No {secureSessionStorage.getItem("PatientName")} found
                          </Typography>
                        )
                      )
                    }
                  </Slider>
                </StyledCard>
              </CardContent>
            </Card>

            <Dialog open={openPatientInfo} onClose={handleClosePatientInfo}>
              {selectedPatient && openPatientInfo && (
                <Card sx={{
                  minWidth: 400,
                  backgroundColor: "#F4F6F8",
                }}>
                  <CardHeader
                    avatar={<Avatar
                      alt={`${selectedPatient.firstName || ''} ${selectedPatient.lastName || ''}`}
                      src={selectedPatient.image || ''}
                      sx={{ width: 120, height: 120 }}
                    />}
                    action={
                      <IconButton color='primary' onClick={handleClosePatientInfo} onMouseDown={handleMouseDown}>
                        <Close />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box sx={{ mt: 2, mb: 3 }}>
                      <Typography variant="h5" sx={{ color: 'text.primary' }}>
                        {`${selectedPatient.firstName || ''} ${selectedPatient.lastName || ''}`}
                      </Typography>

                      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        {selectedPatient.email && selectedPatient.email.toLowerCase() !== 'null' ? selectedPatient.email : ''}
                      </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          Gender
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {selectedPatient.gender || '--'}
                        </Typography>
                      </Box>
                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          Phone
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {selectedPatient.phone || '--'}
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 2, display: "flex", flexDirection: 'column', justifyContent: 'space-around' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                          Location
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                          {selectedPatient.address && Object.keys(selectedPatient.address).length > 0 ? (
                            <>
                              {selectedPatient.address.city && selectedPatient.address.state && selectedPatient.address.country ? (
                                ` ${selectedPatient.address.state}, ${selectedPatient.address.country}`
                              ) : (
                                <>
                                  {selectedPatient.address.city && selectedPatient.address.state ? (
                                    `${selectedPatient.address.city}, ${selectedPatient.address.state}`
                                  ) : (
                                    <>
                                      {selectedPatient.address.city || ""}
                                      {selectedPatient.address.city && selectedPatient.address.state && selectedPatient.address.country ? ", " : ""}
                                      {selectedPatient.address.state || ""}
                                      {selectedPatient.address.state && selectedPatient.address.country ? ", " : ""}
                                      {selectedPatient.address.country || ""}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            "Not Provided"
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button onClick={handleClosePatientInfo} onMouseDown={handleMouseDown}>Close</Button>
                  </CardActions>
                </Card>
              )}
            </Dialog>

          </Grid>

          {/* -----------------------Measurement Graph----------------------------------------------------------------------------------- */}

          <Grid item xs={12} md={12} lg={12} hidden={!showMeasurements}>
            <Card sx={{ height: 450 }}>
              <CardHeader title="Graphical Display" action={
                <>

                  <Tooltip title="Filter Parameter">
                    <Button color='primary' variant='contained' onClick={handleOpenParamFilters} endIcon={<Iconify icon="material-symbols:filter-alt" />}>
                      {selectedParamFilter.label}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Filter Date">
                    <Button className='ml-2' color='primary' variant='contained' onClick={handleOpenDateFilters} endIcon={<Iconify icon="material-symbols:filter-list-rounded" />}>
                      {selectedDateFilter.label}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Select Duration">
                    <Button hidden={selectedDateFilter.value !== "today"} className='ml-2 mr-2' color='primary' variant='contained' onClick={handleOpenDurationFilters} endIcon={<Iconify icon="ph:timer-fill" />}>
                      {selectedDurationFilter.label}
                    </Button>
                  </Tooltip>
                </>
              } />
              <CardContent>
                <Box sx={{ p: 3, pb: 1, height: 350, borderRadius: "10px" }} dir="ltr" style={{ backgroundColor: '#27293d' }}>
                  <canvas ref={canvasRef} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Popover
            open={Boolean(openDurationFilter)}
            anchorEl={openDurationFilter}
            onClose={handleCloseDurationFilters}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Stack spacing={0.75}>
              {durationFilterOptions.map((option) => (
                <MenuItem key={option.value} selected={option.value === selectedDurationFilter.value} onClick={() => handleDurationFilterChange(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </Popover>

          <Popover
            open={Boolean(openDateFilter)}
            anchorEl={openDateFilter}
            onClose={handleCloseDateFilters}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Stack spacing={0.75}>
              {filterOptions.map((option) => (
                <MenuItem key={option.value} selected={option.value === selectedDateFilter.value} onClick={() => handleDateFilterChange(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </Popover>

          <Popover
            open={Boolean(openParamFilter)}
            anchorEl={openParamFilter}
            onClose={handleCloseParamFilters}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 1,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Stack spacing={0.75}>
              {parameters.map((option) => (
                <MenuItem key={option.value} selected={option.value === selectedParamFilter.value} onClick={() => handleParamFilterChange(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </Popover>

          {/* -----------------------Measurement List----------------------------------------------------------------------------------- */}

          <Grid item xs={12} md={12} lg={12} hidden={!showMeasurements}>
            <MeasurementList
              title="Measurement List"
              measurements={measurements}
              setMeasurements={setMeasurements}
              patientId={pidforChart}
              loading={loading}
              setLoading={setLoading}
              onRefresh={chartMeasurements} // Pass chartMeasurements function as a prop
              selectedDurationFilterDash={selectedDurationFilter}
              selectedDateFilterDash={effectiveDateFilter}
              selectedParamFilterDash={selectedParamFilter}
            />
          </Grid>

          {/* -----------------------Device List----------------------------------------------------------------------------------- */}
          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ height: device.length > 0 ? 450 : 100 }}>
              <CardHeader title={secureSessionStorage.getItem("DeviceName") + " List"} />
              <CardContent>
                {
                  device.length > 0 ?
                    loadDevice ? (
                      <center>
                        <CircularProgress />
                      </center>
                    ) : (
                      <StyledCard dir="ltr">
                        <Slider ref={sliderRef} {...settings}>
                          {device.map((devices) => (
                            <Card sx={{ maxWidth: 250, minHeight: 320, boxShadow: 5, marginLeft: 1.5, marginRight: 3 }} key={devices.deviceuuid}>
                              <CardHeader titleTypographyProps={{ variant: 'subtitle2' }} title={devices.devicename} />
                              <CardMedia
                                component="img"
                                height="100"
                                width="100"
                                image={devices.deviceimageName == undefined || devices.deviceimageName === "null" ? DeviceImg : devices.deviceimageName}
                                alt={devices.devicename}
                                sx={{ objectFit: 'contain' }}
                              />
                              <CardContent>
                                <Typography gutterBottom variant="caption" component="div">
                                  Device Id : <b>{devices.decmacid}</b>
                                </Typography>
                                <Typography gutterBottom variant="caption" component="div">
                                  Assigned To : <b>{devices.patientnames == undefined ? "None" : devices.patientnames}</b>
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Tooltip title={secureSessionStorage.getItem("DeviceName") + " info"} onMouseDown={(e) => e.preventDefault()} >
                                  <Button onClick={() => {
                                    setRedirecttoDeviceView(true);
                                    secureSessionStorage.setItem("deviceIdtoView", JSON.stringify(devices));
                                  }} size="small">
                                    view
                                  </Button>
                                </Tooltip>
                                <Tooltip title={secureSessionStorage.getItem("DeviceName") + " Twin"} onMouseDown={(e) => e.preventDefault()} >
                                  <Button onClick={() => {

                                    const deviceId = devices.decmacid;  // Correct field name (from your data)
                                    const deviceName = devices.devicename; // Correct field name
                                    const deviceCategory = devices.deviceCategory; // Correct field name
                                    const deviceGateway = devices.deviceGateway; // Correct field name

                                    const deviceData = { deviceId, deviceName, deviceCategory, deviceGateway }; // Store both device ID and name in localStorage

                                    setRedirecttoDeviceTwin(true);
                                    secureSessionStorage.setItem("deviceIdtoViewGraph", JSON.stringify(deviceData)); // Save device data
                                    secureSessionStorage.setItem("deviceIdtoTwin", JSON.stringify(devices));
                                  }} size="small">
                                    {secureSessionStorage.getItem("DeviceName") + " Twin"}
                                  </Button>
                                </Tooltip>
                              </CardActions>
                            </Card>
                          ))}
                        </Slider>
                      </StyledCard>
                    ) : (
                      <Typography variant="body1" align="center">
                        No {secureSessionStorage.getItem("DeviceName")} found
                      </Typography>
                    )
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div style={{
          height: "50px"
        }} />
      </Container>
    </div >
  );
}
